import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { JobFeature } from "../components/job-components"
import SuccessStory from "../components/success-story"

const SuccessStoryFortune500SelfDriving = ({ data }) => {
  return (
    <SuccessStory
      title="Developing L4 self-driving vehicle technology in a GDPR-compliant manner"
      customerName="Fortune 500 tech company"
      customerLocation="Europe"
      customerDescription="Developing automotive technology."
      customerIndustries="Automotive"
      logo=""
      style={{
        background: "url(/images/traffic.jpg) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <h2 className="my-3">Challenge</h2>
      <p>
        <strong>
          Object recognition using videos has been increasingly gaining momentum
          for many ADAS programs carried out by OEMs and Tier 1. Its development
          requires, among others, the collection of a vast volume of video data,
          taken from all manner of driving scenarios, in order to train the AI
          systems to accurately recognize pedestrians, traffic signs, other
          vehicles, and so on.
        </strong>
      </p>
      <p>
        Our customer developed its own L4 self-driving vehicle program. To
        achieve this, they equipped vehicles with a total of 6 cameras (2
        front-camera, 2 in each side mirror) and planned to proactively collect
        data for building proprietary AI systems.
      </p>
      <Img
        fluid={data.traffic.childImageSharp.fluid}
        className="rounded my-3"
      />
      <p>
        While the collection of videos is essential to the development of their
        autonomous driving system, it might also raise questions to individuals'
        privacy leading to possible GDPR violations, as well as a PR backlash.
      </p>
      <p>
        Therefore an anonymization solution was looked for addressing these
        concerns. The solution had to come with an offline mode in order to meet
        the IT security requirements of the customer.
      </p>
      <h2 className="my-3">Solution</h2>
      <p>
        Celantur’s Container was thoroughly tested by the customer during a
        pilot phase and with support by the Celantur team. Production deployment
        is located on local server infrastructure in a closed network
        environment. Additional interfaces (stream mode) have been added to the
        container, enabling input and output of images as low-level file
        structures. 
      </p>

      <h2 className="my-3">Celantur's contribution</h2>
      <JobFeature>
        Fully-automated anonymization of hundred thousands of street-level images
      </JobFeature>
      <JobFeature>
        New interfaces for fast exchange of low-level file structures
      </JobFeature>
      <JobFeature>Privacy of citizens protected &amp; GDPR-concerns addressed</JobFeature>
    </SuccessStory>
  )
}

export default SuccessStoryFortune500SelfDriving

export const query = graphql`
  query {
    traffic: file(relativePath: { eq: "traffic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
